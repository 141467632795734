<template>
  <b-card no-body class="mb-4 p-3">
    <div>
      <h4 class="card-title">{{ quantidadeItens }} registros</h4>
    </div>
    <div
      class="filtro-valorem d-flex align-items-center justify-content-between mb-2"
    >
      <input-text
        no-label
        :placeholder="$t('PLACEHOLDER.FILTRAR_CONTATO')"
        v-model="filtro"
        class="w-50"
      />
      <b-button @click="abrirModal" variant="primary">
        {{ $t("ESTABELECIMENTOS.NOVO_APPCLIENT") }}
      </b-button>
    </div>

    <div class="d-flex align-items-center mb-2">
      <b-button
        @click="editar(null)"
        v-if="dadosSelecionados.length == 1"
        class="mr-2"
        variant="outline-primary"
      >
        {{ $t("GERAL.EDITAR") }}
      </b-button>
      <b-button
        @click="ligarDesligar()"
        v-if="dadosSelecionados.length == 1"
        :disabled="verificarId()"
        class="mr-2"
        variant="outline-primary"
      >
        {{
          `${!botaoLigarDesligar ? $t("GERAL.LIGAR") : $t("GERAL.DESLIGAR")}`
        }}
      </b-button>
    </div>

    <valorem-tabela
      sem-selecionar-todos
      v-model="tabelaFiltrada"
      :colunas="tabela.colunas"
      ref="tabela"
    >
      <template #cell(ativo)="dados">
        <span
          :class="`text-${
            retornarVarianteLigado(dados.value).variante
          } text-capitalize`"
        >
          {{ retornarVarianteLigado(dados.value).text }}
        </span>
      </template>
    </valorem-tabela>

    <b-row class="mt-3">
      <b-col cols="12" class="text-right mt-3">
        <b-button class="mx-3" variant="outline-primary" @click="cancelar">{{
          $t("GERAL.CANCELAR")
        }}</b-button>
        <b-button variant="primary" @click="continuar">{{
          $t("GERAL.CONTINUAR")
        }}</b-button>
      </b-col>
    </b-row>

    <modal-client-app
      :modalData="dadosSelecionados"
      ref="modalClientApp"
      :form="formClientApp"
      @salvar="salvar"
      @cancelar="limparFormulario"
    />
  </b-card>
</template>

<script>
import InputText from "@/components/inputs/InputText";
//import helpers from "@/common/utils/helpers";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import EstabelecimentoService from "@/common/services/estabelecimentos/estabelecimentos.service";
import ModalClientApp from "./components/ModalClientApp.vue";

export default {
  name: "ClientApp",
  props: {
    form: {
      required: true,
    },
  },
  components: {
    InputText,
    ModalClientApp,
  },
  data() {
    return {
      filtro: "",
      formClientApp: {
        clientId: "",
        secretKey: "",
        plataforma: "",
        adquirente: "",
        ativo: false,
      },
      tabela: {
        colunas: [
          { key: "seletor", label: "" },
          {
            key: "clientId",
            label: this.$t("ESTABELECIMENTOS.APPCLIENT.CLIENTID"),
          },
          {
            key: "secretKey",
            label: this.$t("ESTABELECIMENTOS.APPCLIENT.SECRETKEY"),
          },
          {
            key: "plataforma",
            label: this.$t("ESTABELECIMENTOS.APPCLIENT.PLATAFORMA"),
          },
          {
            key: "adquirente",
            label: this.$t("ESTABELECIMENTOS.APPCLIENT.ADQUIRENTE"),
          },
          {
            key: "ativo",
            label: this.$t("ESTABELECIMENTOS.APPCLIENT.ATIVO"),
          },
        ],
      },
      botaoLigar: null,
      botaoLigarDesligar: null,
    };
  },
  computed: {
    quantidadeItens: function () {
      return this.tabelaFiltrada.length;
    },
    dadosSelecionados: function () {
      return this.tabelaFiltrada.filter((el) => {
        return el.selecionado;
      });
    },
    tabelaFiltrada: {
      set: function (dados) {
        this.form.clientApps = dados;
      },
      get: function () {
        return this.form.clientApps.filter((el) => {
          return (
            el.clientId.includes(this.filtro) ||
            el.secretKey.includes(this.filtro) ||
            el.plataforma.includes(this.filtro)
          );
        });
      },
    },
  },
  methods: {
    verificarId() {
      return (
        this.dadosSelecionados.filter((el) => {
          return !el.id;
        }).length > 0
      );
    },
    continuar: function () {
      this.$emit("submitted");
    },
    cancelar: function () {
      this.$router.push({ name: "estabelecimentos" });
    },
    salvar: function (form) {
      if (!this.formClientApp.id && !this.formClientApp.codigo) {
        form.codigo = Math.random();
        this.form.clientApps.push(form);
      } else {
        this.form.clientApps.splice(form.index, 1, form);
      }
      this.toastSucesso(this.$t(`ESTABELECIMENTOS.APPCLIENT.ADICIONADO`));
      this.$refs.modalClientApp.fecharModal();
    },
    limparFormulario: function () {
      this.formClientApp = {
        clientId: "",
        secretKey: "",
        plataforma: "",
        adquirente: "",
      };
    },
    editar: function () {
      this.formClientApp = this.dadosSelecionados[0];
      let index = null;
      if (this.formClientApp.id)
        index = this.tabelaFiltrada.findIndex(
          ({ id }) => id === this.formClientApp.id
        );
      if (this.formClientApp.codigo)
        index = this.tabelaFiltrada.findIndex(
          ({ codigo }) => codigo === this.formClientApp.codigo
        );
      this.formClientApp.index = index;
      this.$refs.modalClientApp.abrirModal();
    },
    metodoValidacaoSelecionar: function (item) {
      if (this.botaoLigar == null) return true;
      if (item.ativo != this.botaoLigar) return false;
      return true;
    },
    abrirModal() {
      this.limparFormulario();
      this.$refs.modalClientApp.abrirModal();
    },
    alterarStatus() {
      this.dadosSelecionados.map((item) => {
        let index = this.form.clientApps.findIndex(({ id }) => id === item.id);
        item.ativo = !item.ativo;
        this.form.clientApps.splice(index, 1, item);
        item.selecionado = false;
      });
    },
    metodoValidarSelecionar: function (item) {
      if (this.botaoLigarDesligar == null) return true;
      if (item.ativo != this.botaoLigarDesligar) return false;
      return true;
    },
    ligarDesligar: function () {
      this.confirmar(
        this.$t(
          `ESTABELECIMENTOS.APPCLIENT.TITULO_${
            !this.botaoLigarDesligar ? "LIGAR" : "DESLIGAR"
          }`
        ),
        this.$t(
          `ESTABELECIMENTOS.APPCLIENT.CONFIRMACAO_${
            !this.botaoLigarDesligar ? "LIGAR" : "DESLIGAR"
          }`
        ),
        this.$t(
          `ESTABELECIMENTOS.APPCLIENT.BOTAO_${
            !this.botaoLigarDesligar ? "LIGAR" : "DESLIGAR"
          }`
        ),
        this.$t(`GERAL.CANCELAR`)
      ).then((confirmado) => {
        if (!confirmado) return;
        this.$store.dispatch(START_LOADING);
        EstabelecimentoService.ligarClientApp(
          { id: this.dadosSelecionados.map((el) => el.id)[0] },
          !this.botaoLigarDesligar
        )
          .then(() => {
            this.alterarStatus();
            this.toastSucesso(
              this.$t(
                `ESTABELECIMENTOS.APPCLIENT.${
                  !this.botaoLigarDesligar ? "LIGAR" : "DESLIGAR"
                }_SUCESSO`
              )
            );
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },
  },
  watch: {
    dadosSelecionados: function () {
      if (this.dadosSelecionados.length == 1) {
        this.botaoLigarDesligar = this.dadosSelecionados[0].ativo;
      } else if (this.dadosSelecionados.length == 0) {
        this.botaoLigarDesligar = null;
      }
    },
  },
};
</script>
