<template>
  <b-card no-body class="mb-4 p-3">
    <div>
      <h4 class="card-title">{{ quantidadeItens }} registros</h4>
    </div>
    <div
      class="filtro-valorem d-flex align-items-center justify-content-between mb-2"
    >
      <input-text
        no-label
        :placeholder="$t('PLACEHOLDER.FILTRAR_CONTATO')"
        v-model="filtro"
        class="w-50"
      />
      <b-button @click="abrirModal" variant="primary">
        {{ $t("ESTABELECIMENTOS.NOVA_CONTA_BANCARIA") }}
      </b-button>
    </div>

    <div class="d-flex align-items-center mb-2">
      <b-button
        @click="editar(null)"
        v-if="dadosSelecionados.length == 1"
        class="mr-2"
        variant="outline-primary"
      >
        {{ $t("GERAL.EDITAR") }}
      </b-button>
      <b-button
        @click="ativarInativar(botaoAtivarInativar)"
        v-if="dadosSelecionados.length > 0"
        :disabled="verificarId()"
        class="mr-2"
        variant="outline-primary"
      >
        {{
          `${!botaoAtivarInativar ? $t("GERAL.ATIVAR") : $t("GERAL.INATIVAR")}`
        }}
      </b-button>
      <b-button
        @click="confirmGerarToken()"
        v-if="dadosSelecionados.length == 1"
        :disabled="verificarId()"
        class="mr-2"
        variant="outline-primary"
      >
        {{ $t("ESTABELECIMENTOS.GERAR_TOKEN") }}
      </b-button>
      <b-button
        @click="copiar(null)"
        v-if="dadosSelecionados.length == 1"
        variant="outline-primary"
      >
        {{ $t("ESTABELECIMENTOS.COPIAR") }}
      </b-button>
    </div>

    <valorem-tabela
      sem-selecionar-todos
      :metodo-validacao-selecionar="metodoValidacaoSelecionar"
      v-model="tabelaFiltrada"
      :colunas="tabela.colunas"
      ref="tabela"
    >
      <template #cell(ativo)="dados">
        <span
          :class="`text-${
            retornarVariante(dados.value).variante
          } text-capitalize`"
        >
          {{ retornarVariante(dados.value).text }}
        </span>
      </template>
      <template #cell(token)="dados">
        <span
          :class="`text-${
            retornarVarianteToken(dados.value).variante
          } text-capitalize`"
        >
          {{ retornarVarianteToken(dados.value).text }}
        </span>
      </template>
    </valorem-tabela>

    <b-row class="mt-3">
      <b-col cols="12" class="text-right mt-3">
        <b-button variant="outline-primary" @click="cancelar">{{
          $t("GERAL.CANCELAR")
        }}</b-button>
        <b-button
          class="mx-3"
          :disabled="ehNovo()"
          variant="primary"
          @click="salvarcontinuar"
          >{{ $t("GERAL.SALVARCONTINUAR") }}</b-button
        >
        <b-button variant="primary" @click="continuar">{{
          $t("GERAL.SALVAR")
        }}</b-button>
      </b-col>
    </b-row>

    <modal-conta-bancaria
      :modalData="dadosSelecionados"
      ref="modalContaBancaria"
      :form="formContaBancaria"
      @salvar="salvar"
    />
    <modal-token ref="modaToken" :token="token" />
  </b-card>
</template>

<script>
import ModalContaBancaria from "./components/ModalContaBancaria";
import ModalToken from "./components/ModalToken";
import InputText from "@/components/inputs/InputText";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import EstabelecimentoService from "@/common/services/estabelecimentos/estabelecimentos.service";
import { ContaBancaria } from "@/common/models/estabelecimentos/ContaBancaria";
import { AlertasMixin } from "@/common/mixins/AlertasMixin";
import helpers from "@/common/utils/helpers";

export default {
  name: "ContaBancaria",
  mixins: [AlertasMixin],
  props: {
    form: {
      required: true,
    },
  },
  components: {
    InputText,
    ModalContaBancaria,
    ModalToken,
  },
  data() {
    return {
      token: null,
      filtro: "",
      formContaBancaria: new ContaBancaria({}),
      tabela: {
        colunas: [
          { key: "seletor", label: "" },
          {
            key: "nome",
            label: this.$t("ESTABELECIMENTOS.CONVENIO.NOME"),
          },
          { key: "taxa", label: this.$t("ESTABELECIMENTOS.CONVENIO.TAXA") },
          {
            key: "favorecido",
            label: this.$t("ESTABELECIMENTOS.CONVENIO.FAVORECIDO"),
          },
          {
            key: "cpfCnpj",
            label: this.$t("ESTABELECIMENTOS.CONVENIO.CPF_CNPJ"),
          },
          {
            key: "banco",
            label: this.$t("ESTABELECIMENTOS.CONVENIO.BANCO"),
          },
          {
            key: "tipoConta",
            label: this.$t("ESTABELECIMENTOS.CONVENIO.TIPO_CONTA"),
          },
          {
            key: "agencia",
            label: this.$t("ESTABELECIMENTOS.CONVENIO.AGENCIA"),
          },
          {
            key: "conta",
            label: this.$t("ESTABELECIMENTOS.CONVENIO.CONTA"),
          },
          { key: "ativo", label: this.$t("GERAL.STATUS") },
          { key: "token", label: this.$t("ESTABELECIMENTOS.CONVENIO.TOKEN") },
        ],
      },
      botaoAtivarInativar: null,
    };
  },
  computed: {
    quantidadeItens: function () {
      return this.tabelaFiltrada.length;
    },
    dadosSelecionados: function () {
      return this.tabelaFiltrada.filter((el) => {
        return el.selecionado;
      });
    },
    tabelaFiltrada: {
      set: function (dados) {
        this.form.contaBancarias = dados;
      },
      get: function () {
        if (!this.form.contaBancarias) return [];
        return this.form.contaBancarias.filter((el) => {
          return (
            el.nome.includes(this.filtro) ||
            el.email.includes(this.filtro) ||
            el.numeroTelefone.includes(this.filtro)
          );
        });
      },
    },
  },

  methods: {
    verificarId() {
      return (
        this.dadosSelecionados.filter((el) => {
          return !el.id;
        }).length > 0
      );
    },
    continuar: function () {
      this.$emit("salvar");
    },
    cancelar: function () {
      this.$router.push({ name: "estabelecimentos" });
    },
    salvar: function (form) {
      if (!form.id && !form.codigo) {
        form.codigo = Math.random();
        this.form.contaBancarias.push(form);
      } else {
        this.form.contaBancarias.splice(form.index, 1, form);
      }
      this.toastSucesso(this.$t(`ESTABELECIMENTOS.CONTA_BANCARIA.ADICIONADO`));
      this.$refs.modalContaBancaria.fecharModal();
    },
    editar: function () {
      this.formContaBancaria = this.dadosSelecionados[0];
      let index = null;
      if (this.formContaBancaria.id)
        index = this.tabelaFiltrada.findIndex(
          ({ id }) => id === this.formContaBancaria.id
        );
      if (this.formContaBancaria.codigo)
        index = this.tabelaFiltrada.findIndex(
          ({ codigo }) => codigo === this.formContaBancaria.codigo
        );
      this.formContaBancaria.index = index;
      this.$refs.modalContaBancaria.abrirModal();
    },
    metodoValidacaoSelecionar: function (item) {
      if (this.botaoAtivarInativar == null) return true;
      if (item.ativo != this.botaoAtivarInativar) return false;
      return true;
    },
    abrirModal() {
      this.$refs.modalContaBancaria.abrirModal();
    },
    ativarInativar: function (ativarInativar) {
      this.confirmar(
        this.$t(
          `ESTABELECIMENTOS.CONVENIO.TITULO_${
            !ativarInativar ? "ATIVAR" : "INATIVAR"
          }`
        ),
        this.$t(
          `ESTABELECIMENTOS.CONVENIO.CONFIRMACAO_${
            !ativarInativar ? "ATIVAR" : "INATIVAR"
          }`
        ),
        this.$t(
          `ESTABELECIMENTOS.CONVENIO.BOTAO_${
            !ativarInativar ? "ATIVAR" : "INATIVAR"
          }`
        ),
        this.$t(`GERAL.CANCELAR`)
      ).then((confirmado) => {
        if (!confirmado) return;
        this.$store.dispatch(START_LOADING);
        EstabelecimentoService.ativacaoContaBancaria(
          { contaBancariaId: this.dadosSelecionados.map((el) => el.id) },
          !ativarInativar
        )
          .then(() => {
            this.alterarStatus();
            this.toastSucesso(
              this.$t(
                `ESTABELECIMENTOS.CONVENIO.${
                  !ativarInativar ? "ATIVAR" : "INATIVAR"
                }_SUCESSO`
              )
            );
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },
    confirmGerarToken() {
      this.confirmar(
        this.$t("ESTABELECIMENTOS.CONFIRMAR_GERAR_NOVO_TOKEN_TITULO"),
        this.$t("ESTABELECIMENTOS.CONFIRMAR_GERAR_NOVO_TOKEN_MENSAGEM")
      ).then((confirmado) => {
        if (confirmado) this.gerarToken();
      });
    },
    gerarToken() {
      this.$store.dispatch(START_LOADING);
      EstabelecimentoService.gerarToken(this.dadosSelecionados[0].id)
        .then((res) => {
          this.token = res.data.data.aggregateId;
          this.dadosSelecionados[0].token = this.token;

          this.$refs.modaToken.abrirModal();
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    alterarStatus() {
      this.dadosSelecionados.map((item) => {
        let index = this.form.contaBancarias.findIndex(
          ({ id }) => id === item.id
        );
        item.ativo = !item.ativo;
        this.form.contaBancarias.splice(index, 1, item);
        item.selecionado = false;
      });
    },
    copiar: function () {
      this.formContaBancaria = this.dadosSelecionados[0];
      this.formContaBancaria.id = null;
      this.formContaBancaria.token = null;
      let index = null;
      if (this.formContaBancaria.codigo)
        index = this.tabelaFiltrada.findIndex(
          ({ codigo }) => codigo === this.formContaBancaria.codigo
        );
      this.formContaBancaria.index = index;
      this.$refs.modalContaBancaria.abrirModal();
    },
    salvarcontinuar: function () {
      this.$emit("manter");
    },
    ehNovo: function () {
      return this.form.id == undefined;
    },
    retornarVarianteToken: function (token) {
      var existe = token != "";
      return helpers.Token.find((el) => el.value == existe);
    },
  },
  watch: {
    dadosSelecionados: function () {
      if (this.dadosSelecionados.length == 1) {
        this.botaoAtivarInativar = this.dadosSelecionados[0].ativo;
      } else if (this.dadosSelecionados.length == 0) {
        this.botaoAtivarInativar = null;
      }
    },
  },
};
</script>
