<template>
  <valorem-modal
    ref="modal"
    :titulo-modal="$t('ESTABELECIMENTOS.APPCLIENT.TITULO_MODAL_NOVO')"
    @ok="salvar"
    @cancelar="fecharModal"
    tamanho="md"
  >
    <div class="row">
      <input-text
        class="col-12 col-md-6"
        v-model="formModal.clientId"
        :label="$t('ESTABELECIMENTOS.APPCLIENT.CLIENTID')"
        ref="clientId"
        required
      />
      <input-text
        class="col-12 col-md-6"
        v-model="formModal.secretKey"
        :label="$t('ESTABELECIMENTOS.APPCLIENT.SECRETKEY')"
        ref="secretKey"
        required
      />
      <input-select
        class="col-12 col-md-6"
        :options="opcoes.plataforma"
        :label="$t('ESTABELECIMENTOS.APPCLIENT.PLATAFORMA')"
        ref="plataforma"
        v-model="formModal.plataforma"
        required
      />
      <input-select
        class="col-12 col-md-6"
        :options="opcoes.adquirente"
        :label="$t('ESTABELECIMENTOS.APPCLIENT.ADQUIRENTE')"
        ref="adquirente"
        v-model="formModal.adquirente"
        required
      />
    </div>
  </valorem-modal>
</template>
<script>
import { InputText, InputSelect } from "@/components/inputs";
import helpers from "../../../common/utils/helpers";
import cloneDeep from "lodash.clonedeep";

export default {
  name: "ModalClientApp",
  components: {
    InputText,
    InputSelect,
  },
  data() {
    return {
      opcoes: {
        adquirente: helpers.Adquirente,
        plataforma: helpers.Plataforma,
      },
      formModal: {},
    };
  },
  props: {
    form: {
      required: true,
    },
  },
  methods: {
    abrirModal: function () {
      this.$refs.modal.abrirModal();
    },
    fecharModal: function () {
      this.$emit("cancelar");
      this.$refs.modal.fecharModal();
    },
    salvar: function () {
      this.confirmar(
        this.$t("GERAL.CONFIRMAR"),
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then((confirmado) => {
        if (!confirmado) return;

        if (!this.validarFormulario()) return false;

        this.$emit("salvar", this.formModal);
      });
    },
  },
  watch: {
    form: {
      handler() {
        this.formModal = cloneDeep(this.form);
      },
      deep: true,
    },
  },
};
</script>
