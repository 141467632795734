<template>
  <b-card no-body class="mb-4 p-3">
    <div>
      <h4 class="card-title">{{ quantidadeItens }} registros</h4>
    </div>
    <div
      class="filtro-valorem d-flex align-items-center justify-content-between mb-2"
    >
      <input-text
        no-label
        :placeholder="$t('PLACEHOLDER.FILTRAR_CONTATO')"
        v-model="filtro"
        class="w-50"
      />
      <b-button @click="abrirModal" variant="primary">
        {{ $t("ESTABELECIMENTOS.NOVO_CONTATO") }}
      </b-button>
    </div>

    <div class="d-flex align-items-center mb-2">
      <b-button
        @click="editar(null)"
        v-if="dadosSelecionados.length == 1"
        class="mr-2"
        variant="outline-primary"
      >
        {{ $t("GERAL.EDITAR") }}
      </b-button>
      <b-button
        @click="ativarInativar(botaoAtivarInativar)"
        v-if="dadosSelecionados.length > 0"
        :disabled="verificarId()"
        variant="outline-primary"
      >
        {{
          `${!botaoAtivarInativar ? $t("GERAL.ATIVAR") : $t("GERAL.INATIVAR")}`
        }}
      </b-button>
    </div>

    <valorem-tabela
      sem-selecionar-todos
      :metodo-validacao-selecionar="metodoValidacaoSelecionar"
      v-model="tabelaFiltrada"
      :colunas="tabela.colunas"
      ref="tabela"
    >
      <template #cell(ativo)="dados">
        <span
          :class="`text-${
            retornarVariante(dados.value).variante
          } text-capitalize`"
        >
          {{ retornarVariante(dados.value).text }}
        </span>
      </template>
    </valorem-tabela>

    <b-row class="mt-3">
      <b-col cols="12" class="text-right mt-3">
        <b-button class="mx-3" variant="outline-primary" @click="cancelar">{{
          $t("GERAL.CANCELAR")
        }}</b-button>
        <b-button variant="primary" @click="continuar">{{
          $t("GERAL.CONTINUAR")
        }}</b-button>
      </b-col>
    </b-row>

    <modal-contato
      :modalData="dadosSelecionados"
      ref="modalContato"
      :form="formContato"
      @salvar="salvar"
      @cancelar="limparFormulario"
    />
  </b-card>
</template>

<script>
import ModalContato from "./components/ModalContato";
import InputText from "@/components/inputs/InputText";
import helpers from "@/common/utils/helpers";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import EstabelecimentoService from "@/common/services/estabelecimentos/estabelecimentos.service";

export default {
  name: "ContaBancaria",
  props: {
    form: {
      required: true,
    },
  },
  components: {
    InputText,
    ModalContato,
  },
  data() {
    return {
      filtro: "",
      formContato: {
        nome: "",
        numeroTelefone: "",
        email: "",
        tipoContato: "",
      },
      tabela: {
        colunas: [
          { key: "seletor", label: "" },
          {
            key: "tipoContato",
            label: this.$t("ESTABELECIMENTOS.CONTATO.TIPO"),
          },
          { key: "nome", label: this.$t("ESTABELECIMENTOS.CONTATO.NOME") },
          { key: "email", label: this.$t("ESTABELECIMENTOS.CONTATO.EMAIL") },
          {
            key: "numeroTelefone",
            label: this.$t("ESTABELECIMENTOS.CONTATO.CELULAR"),
            formatter: (v) => {
              if (v) return helpers.maskTelefone(v);
            },
          },
          { key: "ativo", label: this.$t("GERAL.STATUS") },
        ],
      },
      botaoAtivarInativar: null,
    };
  },
  computed: {
    quantidadeItens: function () {
      return this.tabelaFiltrada.length;
    },
    dadosSelecionados: function () {
      return this.tabelaFiltrada.filter((el) => {
        return el.selecionado;
      });
    },
    tabelaFiltrada: {
      set: function (dados) {
        this.form.contatos = dados;
      },
      get: function () {
        return this.form.contatos.filter((el) => {
          return (
            el.nome.includes(this.filtro) ||
            el.email.includes(this.filtro) ||
            el.numeroTelefone.includes(this.filtro)
          );
        });
      },
    },
  },

  methods: {
    verificarId() {
      return (
        this.dadosSelecionados.filter((el) => {
          return !el.id;
        }).length > 0
      );
    },
    continuar: function () {
      this.$emit("submitted");
    },
    cancelar: function () {
      this.$router.push({ name: "estabelecimentos" });
    },
    salvar: function (form) {
      if (!this.formContato.id && !this.formContato.codigo) {
        form.codigo = Math.random();
        this.form.contatos.push(form);
      } else {
        this.form.contatos.splice(form.index, 1, form);
      }
      this.toastSucesso(this.$t(`ESTABELECIMENTOS.CONTATO.ADICIONADO`));
      this.$refs.modalContato.fecharModal();
    },
    limparFormulario: function () {
      this.formContato = {
        nome: "",
        numeroTelefone: "",
        email: "",
        tipoContato: "",
      };
    },
    editar: function () {
      this.formContato = this.dadosSelecionados[0];
      let index = null;
      if (this.formContato.id)
        index = this.tabelaFiltrada.findIndex(
          ({ id }) => id === this.formContato.id
        );
      if (this.formContato.codigo)
        index = this.tabelaFiltrada.findIndex(
          ({ codigo }) => codigo === this.formContato.codigo
        );
      this.formContato.index = index;
      this.$refs.modalContato.abrirModal();
    },
    metodoValidacaoSelecionar: function (item) {
      if (this.botaoAtivarInativar == null) return true;
      if (item.ativo != this.botaoAtivarInativar) return false;
      return true;
    },
    abrirModal() {
      this.limparFormulario();
      this.$refs.modalContato.abrirModal();
    },
    ativarInativar: function (ativarInativar) {
      this.confirmar(
        this.$t(
          `ESTABELECIMENTOS.CONTA_BANCARIA.TITULO_${
            !ativarInativar ? "ATIVAR" : "INATIVAR"
          }`
        ),
        this.$t(
          `ESTABELECIMENTOS.CONTA_BANCARIA.CONFIRMACAO_${
            !ativarInativar ? "ATIVAR" : "INATIVAR"
          }`
        ),
        this.$t(
          `ESTABELECIMENTOS.CONTA_BANCARIA.BOTAO_${
            !ativarInativar ? "ATIVAR" : "INATIVAR"
          }`
        ),
        this.$t(`GERAL.CANCELAR`)
      ).then((confirmado) => {
        if (!confirmado) return;
        this.$store.dispatch(START_LOADING);
        EstabelecimentoService.ativacaoContato(
          { contatosId: this.dadosSelecionados.map((el) => el.id) },
          !ativarInativar
        )
          .then(() => {
            this.alterarStatus();
            this.toastSucesso(
              this.$t(
                `ESTABELECIMENTOS.CONTA_BANCARIA.${
                  !ativarInativar ? "ATIVAR" : "INATIVAR"
                }_SUCESSO`
              )
            );
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },
    alterarStatus() {
      this.dadosSelecionados.map((item) => {
        let index = this.form.contatos.findIndex(({ id }) => id === item.id);
        item.ativo = !item.ativo;
        this.form.contatos.splice(index, 1, item);
        item.selecionado = false;
      });
    },
  },
  watch: {
    dadosSelecionados: function () {
      if (this.dadosSelecionados.length == 1) {
        this.botaoAtivarInativar = this.dadosSelecionados[0].ativo;
      } else if (this.dadosSelecionados.length == 0) {
        this.botaoAtivarInativar = null;
      }
    },
  },
};
</script>
