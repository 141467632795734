export class ContaBancaria {
  constructor({
    id,
    nome,
    taxa,
    favorecido,
    cpfCnpj,
    banco,
    tipoConta,
    agencia,
    digitoAgencia,
    conta,
    digitoConta,
    convenio,
    webHookUrl,
    ativo
  }) {
    this.id = id;
    this.nome = nome;
    this.taxa = taxa;
    this.favorecido = favorecido;
    this.cpfCnpj = cpfCnpj;
    this.banco = banco;
    this.tipoConta = tipoConta;
    this.agencia = agencia;
    this.digitoAgencia = digitoAgencia;
    this.conta = conta;
    this.digitoConta = digitoConta;
    this.convenio = convenio;
    this.webHookUrl = webHookUrl;
    this.ativo = ativo;
  }
}
