<template>
  <div>
    <b-card class="mb-4">
      <div class="row">
        <div class="col-lg-2 col-md-5 col-12">
          <b-form-group :label="$t('ESTABELECIMENTOS.ANEXO_ARQUIVO')">
            <upload-foto
              :fotoSrc="form.arquivoLogo"
              @upload="(img) => (form.arquivoLogo = img)"
            />
          </b-form-group>
        </div>
        <div class="col-lg-10 col-md-9 col-12">
          <div class="row">
            <input-mask
              class="col-12 col-md-6"
              :disabled="form.id"
              ref="cpf-cnpj"
              type="cpf_cnpj"
              v-model="form.cpfCnpj"
              :label="$t('ESTABELECIMENTOS.CPF_CNPJ')"
              :placeholder="$t('ESTABELECIMENTOS.CPF_CNPJ')"
              :mask="['###.###.###-##', '##.###.###/####-##']"
              required
            />
            <input-text
              class="col-12 col-md-6"
              ref="parceiro-comercial"
              v-model="form.parceiroComercial"
              :label="$t('ESTABELECIMENTOS.PARCEIRO_COMERCIAL')"
              required
            />
          </div>
          <div class="row">
            <input-text
              class="col-12 col-md"
              ref="razao-social"
              v-model="form.razaoSocial"
              :label="$t('ESTABELECIMENTOS.RAZAO_SOCIAL')"
              required
            />
            <input-text
              class="col-12 col-md"
              ref="nome-fantasia"
              v-model="form.nomeFantasia"
              :label="$t('ESTABELECIMENTOS.NOME_FANTASIA')"
              required
            />
          </div>

          <div class="row">
            <input-text
              id="input-soft"
              class="col-12 col-md-6"
              ref="soft-descriptor"
              v-model="form.softDescriptor"
              :label="$t('ESTABELECIMENTOS.SOFT_DESCRIPTOR')"
              maxLength="30"
              required
            />
            <input-text
              class="col-12 col-md-6"
              ref="ponto-venda"
              v-model="form.pontoVenda"
              :label="$t('ESTABELECIMENTOS.PONTO_VENDA')"
            />
          </div>

          <div class="row">
            <input-autocomplete
              id="input-mcc"
              class="col-12 col-md-12"
              ref="mcc"
              :options="mccOptions"
              v-model="form.codigoMCC"
              :label="$t('ESTABELECIMENTOS.MCC')"
              required
            >
              <template #label>
                <div class="d-inline-flex align-items-center">
                  <span class="mr-2">
                    {{ $t("ESTABELECIMENTOS.MCC") }}
                  </span>
                  <feather
                    type="alert-circle"
                    size="14"
                    v-b-tooltip.hover
                    :title="mccSelecionado"
                  ></feather>
                </div>
              </template>
            </input-autocomplete>
          </div>
        </div>
      </div>

      <b-row>
        <div class="col-12">
          <hr />
        </div>
      </b-row>

      <b-row>
        <h4 class="col-12 mb-4">{{ $t("ESTABELECIMENTOS.TITULOS.TAXAS") }}</h4>
        <input-percentage-novo
          class="col-12 col-md-4"
          :label="$t('ESTABELECIMENTOS.TAXA_DEBITO')"
          v-model="form.taxaDebito"
          :max="100"
          ref="taxaDebito"
          required
          somenteNumeros
        />
        <input-percentage-novo
          class="col-12 col-md-4"
          :label="$t('ESTABELECIMENTOS.TAXA_CREDITO')"
          v-model="form.taxaCredito"
          ref="taxaCredito"
          :max="100"
          required
          somenteNumeros
        />
        <input-percentage-novo
          class="col-12 col-md-4"
          :label="$t('ESTABELECIMENTOS.TAXA_ENTRE2E6')"
          v-model="form.taxaParceladoEntre2e6"
          ref="taxaParceladoEntre2e6"
          :max="100"
          required
          somenteNumeros
        />
      </b-row>

      <b-row>
        <input-percentage-novo
          class="col-12 col-md-4"
          :label="$t('ESTABELECIMENTOS.TAXA_ENTRE7E12')"
          v-model="form.taxaParceladoEntre7e12"
          ref="taxaParceladoEntre7e12"
          :max="100"
          required
          somenteNumeros
        />
        <input-percentage-novo
          class="col-12 col-md-4"
          :label="$t('ESTABELECIMENTOS.TAXA_ENTRE13E21')"
          v-model="form.taxaParceladoEntre13e21"
          ref="taxaParceladoEntre13e21"
          :max="100"
          required
          somenteNumeros
        />
        <input-percentage-novo
          class="col-12 col-md-4"
          :label="$t('ESTABELECIMENTOS.TAXA_ANTECIPACAO_MENSAL')"
          v-model="form.taxaAntecipacaoMensal"
          ref="taxaAntecipacaoMensal"
          :max="100"
          somenteNumeros
          required
        />
      </b-row>

      <b-row>
        <b-form-group
          :label="$t('ESTABELECIMENTOS.RAV')"
          class="col-12 col-md-4"
        >
          <input-checkbox ref="rav" v-model="form.rav" />
        </b-form-group>
        <b-form-group
          :label="$t('ESTABELECIMENTOS.MULTIPLOS')"
          class="col-12 col-md-4"
        >
          <input-checkbox
            ref="multiplosCartoes"
            v-model="form.multiplosCartoes"
          />
        </b-form-group>
        <b-form-group
          :label="$t('ESTABELECIMENTOS.BLOQUEARPARCELAS')"
          class="col-12 col-md-4"
        >
          <input-checkbox
            ref="bloquearNumeroParcelas"
            v-model="form.bloquearNumeroParcelas"
          />
        </b-form-group>
      </b-row>
      <b-row>
        <b-form-group
          :label="$t('ESTABELECIMENTOS.EXIBIRENDERECO')"
          class="col-12 col-md-4"
        >
          <input-checkbox ref="exibirEndereco" v-model="form.exibirEndereco" />
        </b-form-group>
        <b-form-group
          :label="$t('ESTABELECIMENTOS.UTILIZATEF')"
          class="col-12 col-md-4"
        >
          <input-checkbox ref="utilizaTEF" v-model="form.utilizaTEF" />
        </b-form-group>
        <b-form-group
          :label="$t('ESTABELECIMENTOS.ENVIOUNICOWEBHOOK')"
          class="col-12 col-md-4"
        >
          <input-checkbox
            ref="envioUnicoWebhook"
            v-model="form.envioUnicoWebhook"
          />
        </b-form-group>
      </b-row>
      <b-row>
        <b-form-group
          :label="$t('ESTABELECIMENTOS.REENVIOWEBHOOKLIQUIDACAO')"
          class="col-12 col-md-4"
        >
          <input-checkbox
            ref="reenvioWebhookLiquidacao"
            v-model="form.reenvioWebhookLiquidacao"
          />
        </b-form-group>
      </b-row>

      <b-row>
        <b-col cols="12" class="text-right mt-3">
          <b-button class="mx-3" variant="outline-primary" @click="cancelar">{{
            $t("GERAL.CANCELAR")
          }}</b-button>
          <b-button variant="primary" @click="continuar">{{
            $t("GERAL.CONTINUAR")
          }}</b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  InputText,
  InputMask,
  InputPercentageNovo,
  InputAutocomplete,
  InputCheckbox,
} from "@/components/inputs";
import uploadFoto from "@/components/uploadFoto";
import EstabelecimentoService from "@/common/services/estabelecimentos/estabelecimentos.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import helpers from "../../common/utils/helpers";

export default {
  name: "Ficha",
  props: {
    form: {
      required: true,
    },
  },
  components: {
    InputText,
    InputMask,
    InputPercentageNovo,
    InputAutocomplete,
    InputCheckbox,
    uploadFoto,
  },
  mounted() {
    this.buscarMcc();
  },
  data() {
    return {
      arquivo: null,
      arquivoTeste: null,
      mccOptions: [],
      opcoes: {
        adquirente: helpers.Adquirente,
      },
    };
  },
  methods: {
    buscarMcc: function () {
      this.$store.dispatch(START_LOADING);
      EstabelecimentoService.buscarMcc(this.id)
        .then((res) => {
          this.mccOptions = res.data.data.mcc.map((x) => {
            return {
              value: x.id,
              text: x.nome,
            };
          });
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    removerFoto() {
      this.form.arquivoLogo = null;
    },
    cancelar: function () {
      this.$router.push({ name: "estabelecimentos" });
    },
    continuar: function () {
      this.confirmar(
        this.$t("GERAL.CONFIRMAR"),
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then((confirmado) => {
        if (!confirmado) return false;

        if (!this.validarFormulario()) return false;

        if (!this.validarCamposIntegracao()) return false;

        if (this.$refs.arquivoLogo)
          this.form.arquivoLogo = this.$refs.arquivoLogo.getBase64();

        this.$emit("submitted");

        return true;
      });
    },
    validarCamposIntegracao: function () {
      if (this.form.clientId && !this.form.secretKey) {
        this.alertaErro(
          this.$t("ESTABELECIMENTOS.VALIDACAO.MENSAGEM_SECRET_KEY")
        );
        return false;
      }

      if (!this.form.clientId && this.form.secretKey) {
        this.alertaErro(
          this.$t("ESTABELECIMENTOS.VALIDACAO.MENSAGEM_CLIENT_ID")
        );
        return false;
      }

      return true;
    },
  },
  computed: {
    mccSelecionado: function () {
      return this.mccOptions.find((el) => el.value == this.form.codigoMCC);
    },
  },
};
</script>

<style lang="scss">
.formulario-usuario-foto {
  position: absolute;
  margin-top: -100px;
  border-radius: 50%;
  background: #fff;

  .btn-remove {
    position: absolute;
    margin-left: 153px;
    margin-top: -1px;
    cursor: pointer;
  }
}
</style>