<template>
  <valorem-modal
    ref="modalContaBancaria"
    :titulo-modal="$t('ESTABELECIMENTOS.NOVA_CONTA_BANCARIA')"
    @ok="salvar"
    @cancelar="fecharModal"
    tamanho="md"
  >
    <div class="row">
      <input-text
        class="col-12 col-md-12"
        v-model="formModal.nome"
        :label="$t('ESTABELECIMENTOS.CONVENIO.NOME')"
        ref="nome"
        required
      />
      <input-percentage-novo
        class="col-12 col-md-12"
        v-model="formModal.taxa"
        :label="$t('ESTABELECIMENTOS.CONVENIO.TAXA')"
        ref="taxa"
        required
        :max="100"
        somenteNumeros
        />
      <input-text
        class="col-12 col-md-6"
        v-model="formModal.favorecido"
        :label="$t('ESTABELECIMENTOS.CONVENIO.FAVORECIDO')"
        type="text"
        ref="favorecido"
        required
      />
      <input-mask
        class="col-12 col-md-6"
        v-model="formModal.cpfCnpj"
        :label="$t('ESTABELECIMENTOS.CONVENIO.CPF_CNPJ')"
        :mask="['###.###.###-##', '##.###.###/####-##']"
        ref="cpfCnpj"
        required
      />
      <input-text
        class="col-12 col-md-6"
        v-model="formModal.banco"
        :label="$t('ESTABELECIMENTOS.CONVENIO.BANCO')"
        type="text"
        ref="banco"
        required
      />
      <input-select
        class="col-12 col-md-6"
        :options="opcoes.tipoConta"
        :label="$t('ESTABELECIMENTOS.CONVENIO.TIPO_CONTA')"
        ref="tipoConta"
        v-model="formModal.tipoConta"
        required
      />
      <input-text
        class="col-12 col-md-4"
        v-model="formModal.agencia"
        :label="$t('ESTABELECIMENTOS.CONVENIO.AGENCIA')"
        type="text"
        ref="agencia"
        :maxLength="5"
        required
      />
      <input-text
        class="col-12 col-md-2"
        v-model="formModal.digitoAgencia"
        :label="$t('ESTABELECIMENTOS.CONVENIO.DV_AGENCIA')"
        type="text"
        ref="digitoAgencia"
      />
      <input-text
        class="col-12 col-md-4"
        v-model="formModal.conta"
        :label="$t('ESTABELECIMENTOS.CONVENIO.CONTA')"
        type="text"
        ref="conta"
        required
      />
      <input-text
        class="col-12 col-md-2"
        v-model="formModal.digitoConta"
        :label="$t('ESTABELECIMENTOS.CONVENIO.DV')"
        type="text"
        ref="digitoConta"
      />
      <input-text
        class="col-12 col-md-12"
        v-model="formModal.convenio"
        :label="$t('ESTABELECIMENTOS.CONVENIO.CONVENIO')"
        type="text"
        ref="convenio"
        required
      />
      <input-text
        class="col-12 col-md-12"
        v-model="formModal.webHookUrl"
        :label="$t('ESTABELECIMENTOS.CONVENIO.WEBHOOK_URL')"
        type="text"
        ref="webHookUrl"
      />

      <input-text
        class="col-12 col-md-6"
        v-model="formModal.webHookUsuario"
        :label="$t('ESTABELECIMENTOS.CONVENIO.WEBHOOK_USUARIO')"
        type="text"
        ref="webHookUsuario"
      />
      <input-text
        class="col-12 col-md-6"
        v-model="formModal.webHookSenha"
        :label="$t('ESTABELECIMENTOS.CONVENIO.WEBHOOK_SENHA')"
        type="password"
        ref="webHookSenha"
      />
    </div>
  </valorem-modal>
</template>
<script>
import { InputText, InputMask, InputSelect, InputPercentageNovo } from "@/components/inputs";
import helpers from "../../../common/utils/helpers";
import cloneDeep from "lodash.clonedeep";
import { ContaBancaria } from "@/common/models/estabelecimentos/ContaBancaria";
import Toast from "vue-toastification";
import Vue from "vue";
const options = {};
Vue.use(Toast, options);

export default {
  name: "ModalContaBancaria",
  components: {
    InputText,
    InputSelect,
    InputMask,
    InputPercentageNovo,
  },
  data() {
    return {
      opcoes: {
        taxa: helpers.Taxa,
        tipoConta: helpers.TipoContaBancaria,
      },
      formModal: new ContaBancaria({}),
    };
  },
  props: {
    form: {
      required: true,
    },
  },
  methods: {
    abrirModal: function () {
      this.$refs.modalContaBancaria.abrirModal();
    },
    fecharModal: function () {
      this.$refs.modalContaBancaria.fecharModal();
    },
    salvar: function () {
      this.confirmar(
        this.$t("GERAL.CONFIRMAR"),
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then((confirmado) => {
        if (!confirmado) return;

        if (!this.validarFormulario()) return false;
        
        this.formModal.ativo = true;
        this.$emit("salvar", this.formModal);
      });
    },
  },
  watch: {
    form: {
      handler() {
        this.formModal = cloneDeep(this.form);
      },
      deep: true,
    },
  },
};
</script>
