<template>
  <div>
    <b-card class="mb-4">
      <div class="row">
        <input-mask
          class="col-12 col-md-2"
          :label="$t('ESTABELECIMENTOS.CEP')"
          v-model="form.endereco.cep"
          @change="consultaCep"
          :mask="['#####-###']"
          ref="cep"
          required
        />
        <input-text
          class="col-12 col-md-5"
          :label="$t('ESTABELECIMENTOS.ENDERECO')"
          v-model="form.endereco.logradouro"
          ref="logradouro"
          required
        />
        <input-text
          class="col-12 col-md-2"
          :label="$t('ESTABELECIMENTOS.NUMERO')"
          v-model="form.endereco.numero"
          ref="numero"
          required
        />
        <input-text
          class="col-12 col-md-3"
          :label="$t('ESTABELECIMENTOS.COMPLEMENTO')"
          v-model="form.endereco.complemento"
          ref="complemento"
        />
        <input-text
          class="col-12 col-md-3"
          :label="$t('ESTABELECIMENTOS.BAIRRO')"
          v-model="form.endereco.bairro"
          ref="bairro"
          required
        />
        <input-text
          class="col-12 col-md-3"
          :label="$t('ESTABELECIMENTOS.CIDADE')"
          v-model="form.endereco.cidade"
          ref="cidade"
          required
        />
        <input-text
          class="col-12 col-md-3"
          :label="$t('ESTABELECIMENTOS.ESTADO')"
          v-model="form.endereco.uf"
          ref="uf"
          required
        />
        <input-select
          class="col-12 col-md-3"
          :label="$t('ESTABELECIMENTOS.PAIS')"
          :options="opcoes.paises"
          v-model="form.endereco.pais"
          ref="paises"
          required
        />
      </div>
      <b-row>
        <b-col cols="12" class="text-right mt-3">
          <b-button class="mx-3" variant="outline-primary" @click="cancelar">{{
            $t("GERAL.CANCELAR")
          }}</b-button>
          <b-button variant="primary" @click="continuar">{{
            $t("GERAL.CONTINUAR")
          }}</b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { InputText, InputSelect, InputMask } from "@/components/inputs";
import helpers from "../../common/utils/helpers";
import cep from 'cep-promise'

export default {
  name: "Endereco",
  components: {
    InputText,
    InputSelect,
    InputMask,
  },
  props: {
    form: {
      required: true,
    },
  },
  data() {
    return {
      endereco: {
        cep: null,
        logradouro: null,
        numero: null,
        complemento: null,
        bairro: null,
        cidade: null,
        uf: null,
        pais: "BR",
      },
      opcoes: {
        paises: [{ value: "BR", text: "Brasil" }],
      },
    };
  },
  methods: {
    cancelar: function () {
      this.$router.push({ name: "estabelecimentos" });
    },
    continuar: function () {
      this.confirmar(
        this.$t("GERAL.CONFIRMAR"),
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then((confirmado) => {
        if (!confirmado) return;

        if (!this.validarFormulario()) return false;

        this.$emit("submitted");
      });
    },
    consultaCep: function () {
      const value = helpers.removerMascara(this.form.endereco.cep);

      var validacep = /^[0-9]{8}$/;
      if(validacep.test(value)){
        cep(value).then((res)=>{
          this.form.endereco.cep = res.cep;
          this.form.endereco.logradouro = res.street;
          this.form.endereco.bairro = res.neighborhood;
          this.form.endereco.cidade = res.city;
          this.form.endereco.uf = res.state;
          this.form.endereco.pais = 'BR';
        });
      }
    },
  },
};
</script>
