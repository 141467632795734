<template>
  <valorem-modal
    ref="modal"
    :titulo-modal="$t('ESTABELECIMENTOS.CONTATO.TITULO_MODAL_NOVO')"
    @ok="salvar"
    @cancelar="fecharModal"
    tamanho="md"
  >
    <div class="row">
      <input-text
        class="col-12 col-md-6"
        v-model="formModal.nome"
        :label="$t('ESTABELECIMENTOS.CONTATO.NOME')"
        ref="nome"
        required
      />
      <input-text
        class="col-12 col-md-6"
        v-model="formModal.email"
        :label="$t('ESTABELECIMENTOS.CONTATO.EMAIL')"
        type="email"
        ref="email"
        required
      />
      <input-mask
        class="col-12 col-md-6"
        v-model="formModal.numeroTelefone"
        :label="$t('ESTABELECIMENTOS.CONTATO.CELULAR')"
        :mask="['(##) #####-####', '(##) ####-####']"
        ref="numero-telefone"
        required
      />
      <input-select
        class="col-12 col-md-6"
        :options="opcoes.tipos"
        :label="$t('ESTABELECIMENTOS.CONTATO.TIPO')"
        ref="tipo-contato"
        v-model="formModal.tipoContato"
        required
      />
    </div>
  </valorem-modal>
</template>
<script>
import { InputText, InputMask, InputSelect } from "@/components/inputs";
import helpers from "../../../common/utils/helpers";
import cloneDeep from "lodash.clonedeep";

export default {
  name: "ModalContato",
  components: {
    InputText,
    InputSelect,
    InputMask,
  },
  data() {
    return {
      opcoes: {
        tipos: helpers.TipoContato,
      },
      formModal: {},
    };
  },
  props: {
    form: {
      required: true,
    },
  },
  methods: {
    abrirModal: function () {
      this.$refs.modal.abrirModal();
    },
    fecharModal: function () {
      this.$emit("cancelar");
      this.$refs.modal.fecharModal();
    },
    salvar: function () {
      this.confirmar(
        this.$t("GERAL.CONFIRMAR"),
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then((confirmado) => {
        if (!confirmado) return;

        if (!this.validarFormulario()) return false;

        this.$emit("salvar", this.formModal);
      });
    },
  },
  watch: {
    form: {
      handler() {
        this.formModal = cloneDeep(this.form);
      },
      deep: true,
    },
  },
};
</script>
